import './App.css';
import { useSpring, animated, config, easings } from 'react-spring'
import React, { useState } from 'react'
import { Typewriter } from 'react-simple-typewriter'
//import { Link } from 'react-router-dom'

function App() {

    const [showTypewriter, setShowTypewriter] = useState(false);

    const title_anim = useSpring({
        from: {
            opacity: 1,
            //alignItems: "center",
            marginRight: "0rem",
        },
        to: {
            opacity: 1,
            //marginRight: "20rem",
            //alignItems: "left",
        },
        delay: 1000,
        //config: config.stiff,
        config: {
            duration: 1000,
            easing: easings.easeInOutQuint,
        },
    })

    const subtitle_anim = useSpring({
        from: {
            marginRight: 0,
        },
        to: {
            marginRight: 1,
        },
        delay: 200,
        //config: config.molasses
        config: {
            duration: 200,
            easing: easings.easeInOutQuint,
        },
        onRest: () => setTimeout(() => setShowTypewriter(true), 200),
    })


    return (
        <>
            <div className="border-0 border-red-500 wrapper">
                <animated.div className="absolute title" style={title_anim}>
                    <span className="font-bold">heyo!</span><span className="font-extralight"> i'm huxley.</span>
                </animated.div>
                <animated.div className="absolute mt-36 subtitle" style={subtitle_anim}>
                    <span className="italic font-extralight">making dreams into reality since 2026.</span>
                </animated.div>

                <p className="absolute text-base text-center border-0 border-red-500 mt-60 typer">
                    {showTypewriter? <Typewriter
                    words={['this site is under construction..']}
                    cursor
                    delaySpeed={1000}
                    cursorStyle='_'
                    /> : <div>&nbsp;</div>}
                </p>


                <div className="sep"></div>

                <div className=""></div>

            </div>

        </>
    );
}

export default App;
